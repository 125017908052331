@import "../helpers/variable";

.es2move-h1 {
  font-weight: 700;
  line-height: 56px;
  font-size: map-get($font-size-h1, md); }

.es2move-h2 {
  font-weight: 700;
  line-height: 48px;
  font-size: map-get($font-size-h2, md); }

.es2move-h3 {
  font-weight: 700;
  line-height: 40px;
  font-size: map-get($font-size-h3, md); }

.es2move-h4-bold {
  font-weight: 700;
  line-height: 32px;
  font-size: map-get($font-size-h4, md); }

.es2move-h4-medium {
  font-weight: 500;
  line-height: 32px;
  font-size: map-get($font-size-h4, md); }

.es2move-h5-medium {
  font-weight: 500;
  line-height: 28px;
  font-size: map-get($font-size-h5, md); }

.es2move-h5-regular {
  font-weight: 400;
  line-height: 28px;
  font-size: map-get($font-size-h5, md); }

.es2move-body1-bold {
  font-weight: 700;
  line-height: 22px;
  font-size: map-get($font-size-body1, md); }

.es2move-body1-medium {
  font-weight: 500;
  line-height: 22px;
  font-size: map-get($font-size-body1, md); }

.es2move-body1-regular {
  font-weight: 400;
  line-height: 22px;
  font-size: map-get($font-size-body1, md); }

.es2move-body2-medium {
  font-weight: 500;
  line-height: 16px;
  font-size: map-get($font-size-body2, md); }

.es2move-body2-regular {
  font-weight: 400;
  line-height: 16px;
  font-size: map-get($font-size-body2, md); }

.es2move-caption-regular {
  font-weight: 400;
  line-height: 14px;
  font-size: map-get($font-size-caption, md); }

.es2move-overline-regular {
  font-weight: 400;
  line-height: 12px;
  font-size: map-get($font-size-overline, md); }

@include media-breakpoint-up(lg) {
  .es2move-h1 {
    font-size: map-get($font-size-h1, lg); }
  .es2move-h2 {
    font-size: map-get($font-size-h2, lg); }
  .es2move-h3 {
    font-size: map-get($font-size-h3, lg); }
  .es2move-h4-bold {
    font-size: map-get($font-size-h4, lg); }
  .es2move-h4-medium {
    font-size: map-get($font-size-h4, lg); }
  .es2move-h5-medium {
    font-size: map-get($font-size-h5, lg); }
  .es2move-h5-regular {
    font-size: map-get($font-size-h5, lg); }
  .es2move-body1-bold {
    font-size: map-get($font-size-body1, lg); }
  .es2move-body1-medium {
    font-size: map-get($font-size-body1, lg); }
  .es2move-body1-regular {
    font-size: map-get($font-size-body1, lg); }
  .es2move-body2-medium {
    font-size: map-get($font-size-body2, lg); }
  .es2move-body2-regular {
    font-size: map-get($font-size-body2, lg); }
  .es2move-caption-regular {
    font-size: map-get($font-size-caption, lg); }
  .es2move-overline-regular {
    font-size: map-get($font-size-overline, lg); } }

@include media-breakpoint-up(xl) {
  .es2move-h1 {
    font-size: map-get($font-size-h1, xl); }
  .es2move-h2 {
    font-size: map-get($font-size-h2, xl); }
  .es2move-h3 {
    font-size: map-get($font-size-h3, xl); }
  .es2move-h4-bold {
    font-size: map-get($font-size-h4, xl); }
  .es2move-h4-medium {
    font-size: map-get($font-size-h4, xl); }
  .es2move-h5-medium {
    font-size: map-get($font-size-h5, xl); }
  .es2move-h5-regular {
    font-size: map-get($font-size-h5, xl); }
  .es2move-body1-bold {
    font-size: map-get($font-size-body1, xl); }
  .es2move-body1-medium {
    font-size: map-get($font-size-body1, xl); }
  .es2move-body1-regular {
    font-size: map-get($font-size-body1, xl); }
  .es2move-body2-medium {
    font-size: map-get($font-size-body2, xl); }
  .es2move-body2-regular {
    font-size: map-get($font-size-body2, xl); }
  .es2move-caption-regular {
    font-size: map-get($font-size-caption, xl); }
  .es2move-overline-regular {
    font-size: map-get($font-size-overline, xl); } }
