@import '../helpers/variable';
@import '../helpers/mixins';

.header-navbar {
  display: flex;
  align-items: center;
  padding: 0px;
  height: 80px;
  a:hover,
  a:focus {
    text-decoration: none;
  }

  &.active {
    -webkit-box-shadow: 0 4px 8px rgba(7, 34, 74, 0.12);
    -moz-box-shadow: 0 4px 8px rgba(7, 34, 74, 0.12);
    box-shadow: 0 4px 8px rgba(7, 34, 74, 0.12);
  }

  @include smaller-than-lg {
    height: 60px;
    padding: 0px;
  }

  .header-navbar-laptop {
    width: 100%;
    display: flex;
    height: inherit;
    margin: auto auto;
    justify-content: space-around;

    @include smaller-than-lg() {
      display: none;
    }

    .header-navbar-logo {
      display: flex;
      height: inherit;

      .header-logo {
        margin: auto 0px;
      }

      .header-partner-link {
        margin: auto auto;
        margin-left: 48px;
        @include font-size('h5');
        @include font-weight('regular');
        @include line-height('h5');
        @include smaller-than-xl {
          padding: 0px;
          margin-left: 12px;
          @include font-size('body1');
          @include font-weight('regular');
          @include line-height('body1');
        }

        span {
          color: $color-black-100;
          &:hover {
            color: $color-black-50;
          }
          &:active {
            color: $color-blue-50;
          }

          @include smaller-than-lg() {
            display: none;
          }
        }
      }
    }

    .header-navbar-functions {
      height: inherit;
      flex-direction: row-reverse;

      .header-navbar-collapse {
        height: inherit;
        width: 100%;

        .header-language {
          display: flex;
          justify-content: flex-end;
          margin: auto 0px;
          padding: 0px;

          .header-language-current {
            display: inline-block;
            margin-right: 6px;

            img {
              display: inline-block;
              margin-right: 16px;
              vertical-align: middle;
              @include smaller-than-xl {
                margin-right: 4px;
              }
            }

            p {
              display: inline-block;
              color: $color-black-100;
              @include font-size('h5');
              @include font-weight('regular');
              @include line-height('h5');
              @include smaller-than-xl {
                @include font-size('body1');
                @include font-weight('regular');
                @include line-height('body1');
              }
            }
          }

          .header-language-option {
            .dropdown-menu {
              min-width: 158px;
              width: fit-content;
              padding: 0px;
              background: $color-white-100;
              border: 1px solid $color-black-10;
              box-sizing: border-box;
              -webkit-box-shadow: 0 4px 8px rgba(7, 34, 74, 0.18);
              -moz-box-shadow: 0 4px 8px rgba(7, 34, 74, 0.18);
              box-shadow: 0 4px 8px rgba(7, 34, 74, 0.18);
              border-radius: 5px;
            }
            .dropdown-toggle {
              padding: 8px;
            }
            .header-language-item {
              width: 158px;
              display: flex;
              align-items: center;
              height: 48px;
              padding: 13px;
              padding-left: 16px;
              margin: auto;

              @include font-size('h5');
              @include font-weight('regular');
              @include line-height('h5');
              @include smaller-than-xl {
                @include font-size('body1');
                @include font-weight('regular');
                @include line-height('body1');
              }
              color: $color-black-100;

              &:hover {
                background-color: $color-black-10;
              }

              &:active,
              &::selection {
                background-color: $color-blue-10;
              }
            }
          }
        }

        .dropdown-toggle::after {
          vertical-align: middle;
        }

        .header-searchbar {
          width: inherit;
          display: flex;
          margin: auto auto;
          padding: 0px;

          .header-searchbar-input {
            width: inherit;
            border: 1px solid $color-black-50;
            border-radius: 5px;

            &.active {
              border: 1px solid $color-blue-50;
            }

            input {
              width: inherit;
              margin: 9px 0px 9px 16px;
              border: none;
              @include font-size('body1');
              @include font-weight('regular');
              @include line-height('body1');
              @include smaller-than-xl {
                margin: 9px auto 9px 8px;
                @include font-size('body2');
                @include font-weight('regular');
                @include line-height('body2');
              }

              &::placeholder {
                color: $color-black-50;
              }
            }

            img {
              vertical-align: middle;
              margin-right: 16px;
            }

            @include smaller-than-xl() {
              @include font-size('body2');
              @include font-weight('regular');
              @include line-height('body2');
            }
          }
          .header-searchbar-icon {
            background-color: $color-white-100;
            border: 1px solid rgba(129, 140, 158, 1);
            border-left: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
        .header-neworder {
          display: flex;
          margin: auto 0px;
          padding: 0px;

          .header-neworder-button {
            width: inherit;
            .button {
              width: inherit;
              padding: 10px 0px 10px 0px;
              @include font-size('h5');
              @include font-weight('medium');
              @include line-height('h5');
              -webkit-box-shadow: 0 4px 8px rgba(51, 151, 247, 0.35);
              -moz-box-shadow: 0 4px 8px rgba(51, 151, 247, 0.35);
              box-shadow: 0 4px 8px rgba(51, 151, 247, 0.35);

              @include smaller-than-xl {
                padding: 10px 0px 10px 0px;
                @include font-size('body1');
                @include line-height('body1');
              }
            }
          }
        }

        .header-divider {
          margin: auto 18px;
          border-right: 1px solid #b7beca;
          height: 32px;

          @include smaller-than-xl {
            margin: auto 8px;
          }
        }
      }
    }
  }

  .header-navbar-mobile {
    width: 100%;
    display: none;
    margin: auto auto;

    @include smaller-than-lg() {
      height: inherit;
      display: flex;
      justify-content: space-between;
    }

    .header-navbar-logo {
      display: flex;
      height: inherit;

      @include smaller-than-lg {
        padding-left: 48px;
      }

      @include smaller-than-md {
        padding-left: 20px;
      }
      a.navbar-brand {
        padding-top: 16px;
        padding-bottom: 20px;
      }

      .header-logo {
        margin: auto 0px;
        img {
          @include smaller-than-lg() {
            width: 100%;
          }

          @include smaller-than-sm() {
            width: 80%;
          }
        }
      }
    }

    .header-navbar-functions {
      height: inherit;
      @include smaller-than-lg {
        padding-right: 48px;
      }
      @include smaller-than-md {
        padding-right: 20px;
      }
      .header-mobile-icons.navbar-brand {
        height: inherit;

        img {
          @include smaller-than-lg {
            width: 75%;
          }
        }
        @include smaller-than-lg {
          padding-top: 14px;
          margin: 0px;
        }
      }
    }

    .header-searchbar {
      width: inherit;
      height: 1000px;
      padding: 20px;
      background-color: $color-black-10;

      .header-searchbar-input {
        max-width: 768px;
        margin: auto auto;
        height: 40px;
        background-color: $color-white-100;
        border: 1px solid rgba(129, 140, 158, 1);
        border-radius: 5px;

        &.active {
          border: 1px solid $color-blue-50;
        }

        input {
          width: -webkit-fill-available;
          margin: auto 0px auto 16px;
          border: none;
          background-color: transparent;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }

        img {
          vertical-align: text-bottom;
          margin-right: 16px;
        }
      }

      .header-searchbar-quickquote {
        flex-wrap: wrap;
        text-align: center;
        img {
          margin: 82px auto;
          max-width: 325px;
        }
        text.quickquote-title {
          font-size: 20px;
          font-weight: 700;
          line-height: 32px;
        }
        text.quickquote-desc {
          @include font-size('body2');
          @include font-weight('regular');
          @include line-height('body2');
        }
      }
    }

    .header-burger {
      width: inherit;
      .header-burger-contents {
        width: inherit;
        background-color: $color-white-100;

        .header-burger-partner-link {
          margin: 0px 32px 0px;
          padding-top: 24px;
          padding-bottom: 24px;

          @include font-size('h5');
          @include font-weight('medium');
          @include line-height('h5');

          border-bottom: 1px solid $color-black-30;
          a {
            color: $color-black-100;
          }
        }

        .header-burger-language {
          position: relative;
          display: flex;
          margin: 0px 32px 0px;
          padding-top: 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid $color-black-30;

          .header-language-item {
            width: inherit;

            &:hover {
              background-color: $color-black-10;
            }

            &:active,
            &::selection {
              background-color: $color-blue-10;
            }
          }

          .header-burger-language-current {
            display: inline-block;
            cursor: pointer;
            img {
              display: inline-block;
              vertical-align: middle;
              &:nth-of-type(odd) {
                margin-right: 16px;
              }
              &:nth-of-type(even) {
                margin-left: 16px;
              }
            }

            p {
              display: inline-block;
              @include font-size('body1');
              @include font-weight('regular');
              @include line-height('body1');
            }
          }

          .header-burger-language-option {
            display: flex;
            flex-wrap: wrap;
            width: 158px;
            position: absolute;
            top: 80%;
            padding: 4px 0px;
            background-color: $color-white-100;
            @include font-size('body1');
            @include font-weight('regular');
            @include line-height('body1');
            border: 1px solid #f8f9fb;
            border-radius: 5px;
            -webkit-box-shadow: 0 4px 8px rgba(7, 34, 74, 0.18);
            -moz-box-shadow: 0 4px 8px rgba(7, 34, 74, 0.18);
            box-shadow: 0 4px 8px rgba(7, 34, 74, 0.18);
            cursor: pointer;

            div {
              display: block;
              width: inherit;
              background-color: $color-white-100;
              &:hover {
                background-color: $color-black-10;
              }
            }
            p {
              color: $color-black-100;
              margin: 13px 16px;
            }
          }
        }

        .header-burger-neworder {
          display: flex;
          width: inherit;

          padding: 24px 0px 32px;

          .header-burger-neworder-button {
            width: inherit;
            margin: auto 32px;
            padding: 10px;
            border: none;
            @include font-size('h5');
            @include font-weight('medium');
            @include line-height('h5');
            -webkit-box-shadow: 0 4px 8px rgba(51, 151, 247, 0.35);
            -moz-box-shadow: 0 4px 8px rgba(51, 151, 247, 0.35);
            box-shadow: 0 4px 8px rgba(51, 151, 247, 0.35);
          }
        }

        .header-burger-divider {
          margin: 24px auto;
          border-bottom: 1px solid #b7beca;
          width: 100%;

          @include smaller-than-sm() {
            width: 80vw;
          }
        }
      }

      .header-burger-dark-bg {
        height: 1000px;
        background-color: rgba($color: #000000, $alpha: 0.5);
      }
    }
  }
}
