@import '../helpers/variable';
@import '../helpers/mixins';

body {
  overflow-x: hidden;
  margin-top: 80px !important;

  @include smaller-than-lg() {
    margin-top: 60px !important;
  }

  .__body__contact__agent {
    z-index: 2;
    position: fixed;
    right: 8px;
    bottom: 4px;

    @include smaller-than-md {
      right: 0px;
      bottom: -4px;
    }
  }

  .__body__main {
    height: 820px;
    background-color: $color-blue-50;
    color: $color-white-100;
    text-align: center;

    @include smaller-than-md {
      height: 720px;
    }

    #main-subtitle-linebreaker {
      @include smaller-than-lg {
        display: none;
      }
    }

    .__body__main__title {
      padding-top: 96px;
      margin-bottom: 16px;

      text {
        display: inline-block;
        @include font-size('h1');
        @include font-weight('bold');
        @include line-height('h1');
      }

      img#comp-logo-laptop {
        display: inline-block;
        vertical-align: sub;
        margin-left: 16px;
      }

      img#comp-logo-mobile {
        display: none;
      }

      @include smaller-than-lg {
        padding-top: 32px;

        text {
          display: block;
          @include font-size('h2');
          @include font-weight('bold');
          @include line-height('h2');
        }

        img#comp-logo-mobile {
          display: block;
          margin: auto auto;
          margin-bottom: 32px;
        }

        img#comp-logo-laptop {
          display: none;
        }
      }
    }

    .__body__main__subtitle {
      margin-bottom: 48px;
      text {
        @include font-size('h5');
        @include font-weight('regular');
        @include line-height('h5');

        @include smaller-than-lg {
          @include font-size('body1');
          @include font-weight('regular');
          @include line-height('body1');
        }
      }
    }


    @include smaller-than-lg{
      .mobile-inactive{
        display: none;
      }
    }

    @include more-than-lg{
      .mobile-active{
        display: none;
      }
    }

    .__body__main__order__btn {
      margin: auto auto 16px;
      width: 183px;
      
      .button {
        width: inherit;
        text-align: center;
        background-color: $color-yellow-50;
        font-size: 20px;
        font-weight: 500;
        color: $color-white-100;
        border: 0.5px solid $color-yellow-30;
        box-sizing: border-box;
        -webkit-box-shadow: 4px 4px 8px rgba(238, 178, 51, 0.35);
        -moz-box-shadow: 4px 4px 8px rgba(238, 178, 51, 0.35);
        box-shadow: 4px 4px 8px rgba(238, 178, 51, 0.35);
        border-radius: 5px;

        @include smaller-than-lg {
          margin: auto auto 64px;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .__body__main__crew__img {
      position: relative;
      img {
        width: 1052px;
        position: absolute;
        left: -15px;

        @include smaller-than-lg {
          width: 90%;
        }

        @include smaller-than-md {
          width: 100%;
        }
      }
    }
  }

  .__body__prices {
    position: relative;
    width: 100%;
    background-color: $color-black-20;
    color: $color-black-100;
    text-align: center;

    .__body__prices__flag {
      position: absolute;
      right: 0px;
      margin-right: 15px;
      text-align: right;
      color: $color-white-100;

      @include smaller-than-xl {
        position: relative;
      }

      .flag-img {
        position: inline;
        font-size: 64px;
        font-weight: 700;
      }

      .flag-text {
        position: absolute;
        width: 180px;
        top: 10%;
        right: 0px;
        text-align: center;

        text {
          margin: 0px;
          color: $color-white-100;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          @include font-size('h5');
          @include font-weight('regular');
          @include line-height('h5');

          @include smaller-than-lg {
            @include font-size('body1');
            @include font-weight('regular');
            @include line-height('body1');
          }

          &:last-of-type {
            font-size: 64px;
            font-weight: 700;
            line-height: 96px;
            -webkit-text-shadow: 4px 4px 8px rgba(166, 105, 16, 0.22);
            -moz-text-shadow: 4px 4px 8px rgba(166, 105, 16, 0.22);
            text-shadow: 4px 4px 8px rgba(166, 105, 16, 0.22);

            @include smaller-than-lg {
              font-size: 56px;
              line-height: 84px;
            }
          }
        }
      }
    }

    .__body__prices__title {
      text {
        @include font-size('h3');
        @include font-weight('bold');
        @include line-height('h3');
        @include smaller-than-lg {
          @include font-size('h4');
          @include font-weight('bold');
          @include line-height('h4');
        }
      }
    }

    .__body__prices__subtitle {
      text {
        @include font-size('h5');
        @include font-weight('regular');
        @include line-height('h5');
        @include smaller-than-lg {
          @include font-size('body1');
          @include font-weight('regular');
          @include line-height('body1');
        }
      }
    }

    .__body__prices__type {
      margin: 56px auto 32px;

      .btn {
        cursor: pointer;
      }
      .__body__prices__type__btn {
        display: inline;
        background-color: $color-black-10;
        color: $color-blue-50;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        @include smaller-than-lg {
          font-size: 14px;
        }
      }
      .left {
        padding: 9px 29px 9px 31px;
        border: 1.5px solid #3397f7;
        border-radius: 5px 0px 0px 5px;

        &.active {
          background: $color-blue-50;
          color: $color-white-100;
        }
      }
      .right {
        padding: 9px 33px 9px 35px;
        border: 1.5px solid #3397f7;
        border-left: none;
        border-radius: 0px 5px 5px 0px;

        &.active {
          background: $color-yellow-50;
          color: $color-white-100;
        }
      }
      .express {
        color: $color-yellow-50;
        border: 1.5px solid #ffbb2a;
      }
    }

    .__body__prices__body {
      padding-bottom: 31px;

      @include smaller-than-xl {
        padding-bottom: 7px;
      }

      .product {
        @include smaller-than-xl {
          margin-bottom: 24px;
        }
        padding: 32px 24px 24px;
        background-color: $color-white-100;
        border: 1px solid #f8f9fb;
        box-sizing: border-box;
        -webkit-box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
        -moz-box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
        box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
        border-radius: 5px;

        .product-title {
          color: $color-black-100;
          text {
            @include font-size('h5');
            @include font-weight('medium');
            @include line-height('h5');
            @include smaller-than-lg {
              @include font-size('body1');
              @include font-weight('medium');
              @include line-height('body1');
            }
          }
        }
        .product-desc {
          height: 44px;
          margin-top: 16px;
          color: $color-black-50;
          @include font-size('body1');
          @include font-weight('regular');
          @include line-height('body1');
          @include smaller-than-lg {
            @include font-size('body2');
            @include font-weight('regular');
            @include line-height('body2');
          }
        }
        .product-img {
          margin-top: 20px;
        }
        .product-price {
          color: $color-black-50;
          margin: 20px auto;
          @include font-size('body1');
          @include font-weight('regular');
          @include line-height('body1');
          @include smaller-than-lg {
            @include font-size('body2');
            @include font-weight('regular');
            @include line-height('body2');
          }

          span {
            &:last-of-type {
              color: $color-blue-50;
              @include font-size('h2');
              @include font-weight('bold');
              @include line-height('h2');
              @include smaller-than-lg {
                @include font-size('h3');
                @include font-weight('bold');
                @include line-height('h3');
              }
              &.express {
                color: $color-yellow-50;
              }
            }
          }
        }
        .product-quote {
          background-color: $color-blue-50;
          color: $color-white-100;
          font-weight: 500;
          font-size: 20px;
          @include smaller-than-md {
            font-size: 16px;
          }

          &.express {
            background-color: $color-yellow-50;
            color: $color-white-100;
          }
        }
      }
    }

    .__body__prices__footnote {
      max-width: 832px;
      margin: auto;
      padding-bottom: 32px;

      @include smaller-than-lg {
        padding-bottom: 56px;
      }

      text-align: center;
      text {
        @include font-size('body1');
        @include font-weight('regular');
        @include line-height('body1');
        @include smaller-than-lg {
          @include font-size('body2');
          @include font-weight('regular');
          @include line-height('body2');
        }

        p {
          display: inline;
          @include font-weight('medium');
          @include smaller-than-lg {
            @include font-weight('medium');
          }
        }
      }
    }
  }

  .__body__services {
    padding-bottom: 148px;
    position: relative;
    width: 100%;
    background-color: $color-white-100;
    color: $color-black-100;
    text-align: center;

    @include smaller-than-lg {
      padding-bottom: 48px;
    }

    .__body__services__title {
      @include font-size('h3');
      @include font-weight('bold');
      @include line-height('h3');
      @include smaller-than-md {
        @include font-size('h4');
        @include font-weight('bold');
        @include line-height('h4');
      }
    }

    .__body__services__subtitle {
      margin-bottom: 100px;
      @include font-size('h5');
      @include font-weight('regular');
      @include line-height('h5');
      @include smaller-than-md {
        margin-bottom: 56px;
        @include font-size('body1');
        @include font-weight('regular');
        @include line-height('body1');
      }
    }
    .__body__services__body {
      text-align: left;
      .service {
        margin-bottom: 64px;
        .service-img {
          margin-bottom: 24px;
        }
        .service-title {
          margin-bottom: 16px;
          text {
            @include font-size('h5');
            @include font-weight('medium');
            @include line-height('h5');
            @include smaller-than-md {
              @include font-size('body1');
              @include font-weight('medium');
              @include line-height('body1');
            }
          }
        }
        .service-desc {
          color: $color-black-50;
          text {
            @include font-size('body1');
            @include font-weight('regular');
            @include line-height('body1');
            @include smaller-than-md {
              @include font-size('body2');
              @include font-weight('regular');
              @include line-height('body2');
            }
          }
        }
        @include smaller-than-lg {
          margin-bottom: 36px;
        }
      }
    }
  }

  .__body__return {
    position: relative;
    width: 100%;
    background-color: $color-black-20;
    color: $color-black-100;
    text-align: center;
    .__body__return__title {
      @include font-size('h3');
      @include font-weight('bold');
      @include line-height('h3');
      @include smaller-than-md {
        @include font-size('h4');
        @include font-weight('bold');
        @include line-height('h4');
      }
    }
    .__body__return__subtitle {
      margin-bottom: 110px;
      @include font-size('h5');
      @include font-weight('regular');
      @include line-height('h5');
      @include smaller-than-md {
        margin-bottom: 56px;
        @include font-size('body1');
        @include font-weight('regular');
        @include line-height('body1');
      }
    }

    .__body__return__bottom {
      padding-bottom: 155px;

      @include smaller-than-lg {
        padding-bottom: 56px;
      }
      text {
        margin: auto 0px;
        text-align: left;
        @include font-size('body1');
        @include font-weight('regular');
        @include line-height('body1');
        @include smaller-than-lg {
          margin-bottom: 56px;
          @include font-size('body2');
          @include font-weight('regular');
          @include line-height('body2');
        }
      }
      img {
        width: 520px;
        margin: auto auto;
        @include smaller-than-xl {
          width: 40%;
        }
        @include smaller-than-lg {
          width: 75%;
          margin: auto auto;
          padding-bottom: 56px;
        }
      }
    }
  }

  .__body__discount {
    position: relative;
    width: 100%;
    background-color: $color-white-100;
    color: $color-blue-50;
    text-align: center;
    .__body__discount__img {
      width: auto;
      margin: 73px auto 60px;
      @include smaller-than-lg {
        margin: 56px auto 72px;
      }
    }
    .__body__discount__body {
      margin: auto auto;
      #percent {
        margin-bottom: 0px;
        padding: 0px;
        text-align: left;
        font-size: 90px;
        font-weight: 900;
        line-height: 135px;
        font-style: italic;
        @include smaller-than-lg {
          margin-bottom: 16px;
          text-align: center;
          font-size: 72px;
          line-height: 80px;
        }
      }
      #description {
        margin: auto auto;
        text-align: left;
        @include smaller-than-lg {
          margin-bottom: 56px;
          text-align: center;
        }
        text {
          @include font-size('h3');
          @include font-weight('bold');
          @include line-height('h3');
          @include smaller-than-md {
            @include font-size('h4');
            @include font-weight('bold');
            @include line-height('h4');
          }
        }
      }
      .button {
        display: block;
        width: 183px;
        background-color: $color-yellow-50;
        color: $color-white-100;
        font-size: map-get($font-size-h5, xl);
        font-weight: 500;
        -webkit-box-shadow: 0px 4px 8px rgba(238, 178, 51, 0.35);
        -moz-box-shadow: 0px 4px 8px rgba(238, 178, 51, 0.35);
        box-shadow: 0px 4px 8px rgba(238, 178, 51, 0.35);

        @include smaller-than-lg {
          margin: auto auto 56px;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}

.title {
  padding-top: 72px;
  color: $color-blue-50;
  @include smaller-than-lg {
    padding-top: 56px;
  }
}

.subtitle {
  padding-top: 16px;
  color: $color-black-100;
}
