@import '../helpers/variable';
@import '../helpers/mixins';

.__driver {
  background-color: $color-black-20;
  padding-bottom: 260px;

  .row {
    &:first-child {
      padding-top: 96px;

      @include smaller-than-md {
        padding-top: 32px;
      }
    }
  }
  .__driver__title {
    margin-bottom: 16px;
    text {
      color: $color-blue-50;
      @include font-size('h3');
      @include font-weight('bold');
      @include line-height('h3');

      @include smaller-than-md {
        @include font-size('h4');
        @include font-weight('bold');
        @include line-height('h4');
      }
    }
  }

  .__driver__desc {
    margin-bottom: 56px;
    text {
      color: $color-black-100;
      @include font-size('h5');
      @include font-weight('regular');
      @include line-height('h5');

      @include smaller-than-md {
        @include font-size('body1');
        @include font-weight('regular');
        @include line-height('body1');
      }
    }
  }

  .__driver__features {
    .__driver__feature {
      margin-bottom: 32px;
      .feature-image {
        display: inline;
        height: 100%;
        margin: auto 0px;
        margin-right: 24px;
        img {
          vertical-align: bottom;
        }
      }
      .feature-text {
        margin: auto 0px;
        display: inline;
        color: $color-black-100;
        @include font-size('h5');
        @include font-weight('medium');
        @include line-height('h5');

        @include smaller-than-md {
          @include font-size('body1');
          @include font-weight('medium');
          @include line-height('body1');
        }
      }
    }
  }

  .__driver__form {
    height: inherit;
    margin: 0px auto;
    .__driver__form__input__group {
      height: inherit;
      padding: 24px 32px;
      background-color: $color-white-100;
      color: $color-black-50;
      border: 1px solid $color-black-10;
      border-radius: 5px;
      -webkit-box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
      -moz-box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
      box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);

      textarea::placeholder {
        color: $color-black-50;
      }
    }

    .input-title {
      margin-top: 24px;
      margin-bottom: 23px;
      color: $color-blue-50;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }

    .input-blank {
      margin-bottom: 39px;
      padding-bottom: 9px;
      border: none;
      border-bottom: 1px solid $color-black-50;
      width: 100%;
    }
    .btns {
      position: relative;

      .btn {
        margin: 12px auto;
        font-size: 20px;
        font-weight: 500;
      }

      .btn-submit {
        position: absolute;
        right: 0%;
        background-color: $color-blue-50;
        color: $color-white-100;
        -webkit-box-shadow: 0px 4px 8px rgba(145, 209, 241, 0.3);
        -moz-box-shadow: 0px 4px 8px rgba(145, 209, 241, 0.3);
        box-shadow: 0px 4px 8px rgba(145, 209, 241, 0.3);
      }
    }
  }
}

.driver-snack-alert {
  .MuiPaper-elevation6 {
    -webkit-box-shadow: 0px 4px 8px rgba(24, 135, 114, 0.2);
    -moz-box-shadow: 0px 4px 8px rgba(24, 135, 114, 0.2);
    box-shadow: 0px 4px 8px rgba(24, 135, 114, 0.2);
    border-radius: 5px;
  }

  .MuiAlert-filledSuccess {
    background-color: $color-green-50;
  }
  .MuiAlert-icon {
    margin: auto auto;
  }
  .MuiAlert-message {
    margin: 0px 0px 0px 16px;
    @include font-size('body1');
    @include font-weight('medium');
    @include line-height('body1');
    @include smaller-than-md {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}

@include smaller-than-lg {
  .btns {
    display: grid;
  }
}

@include smaller-than-md {
  .__driver {
    padding-bottom: 0px;
    .__driver__form {
      margin: 56px auto;
    }

    .__driver__img {
      text-align: center;
    }
    .btns {
      display: inline;
      .btn {
        float: none;
        margin: 12px auto;
        width: 100%;
        display: block;
        font-size: 20px;
        font-weight: 500;
      }
      .btn-submit {
        position: relative !important;
      }
    }
  }
}
