@import '../helpers/variable';
@import '../helpers/mixins';

.__search {
  height: 820px;
  background-color: $color-blue-50;
  align-items: flex-end;

  @include smaller-than-md {
    height: fit-content;
    flex-direction: column;
    justify-content: space-between;
  }

  .__search__botcheckarea {
    min-height: 617px;
    .__search__recaptcha {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .__search__background {
    width: 100%;
  }

  .__search__status {
    position: absolute;
    top: 37.5%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include smaller-than-lg {
      height: fit-content;
    }

    @include smaller-than-md {
      position: relative;
      top: initial;
      left: initial;
      transform: initial;
      margin: 32px auto 70px;
    }

    @include smaller-than-sm {
      margin: 32px 20px 70px 20px;
    }

    .__search__status__title {
      color: $color-white-100;
      margin-bottom: 32px;

      @include font-size('h3');
      @include font-weight('bold');
      @include line-height('h3');

      @include smaller-than-md {
        @include font-size('h3');
        @include font-weight('bold');
        @include line-height('h3');
      }
    }

    .__search__status__board {
      height: inherit;
      margin: auto auto;
      padding: 24px 32px;
      background-color: $color-white-100;
      border: 1px solid $color-black-10;
      -webkit-box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
      -moz-box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
      box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
      border-radius: 5px;

      .board-header {
        display: flex;
        align-items: center;

        .stepper-img {
          margin-right: 24px;

          @include smaller-than-lg {
            display: none;
          }
        }

        .stepper-text {
          .status {
            margin-bottom: 13px;
            font-weight: 700;
            font-size: 24px;
            line-height: 28.13px;

            @include smaller-than-md {
              font-weight: 700;
              font-size: 20px;
              line-height: 23.44px;
            }
          }

          .share-link {
            @include font-size('body1');
            @include font-weight('regular');
            @include line-height('body1');
            @include smaller-than-md {
              @include font-size('body2');
              @include font-weight('regular');
              @include line-height('body2');
            }

            .share-link-break {
              display: none;
              @include smaller-than-lg {
                display: initial;
              }
              @include smaller-than-md {
                display: none;
              }
            }

            img {
              vertical-align: middle;
              margin-left: 16px;
              margin-right: 12px;
              @include smaller-than-lg {
                margin-left: 0px;
                margin-right: 4px;
              }
            }

            p {
              display: inline;
              color: $color-blue-50;
              @include font-weight('medium');
              @include smaller-than-md {
                font-size: 14px;
                font-weight: 500;
                line-height: 19.6px;
              }
            }
          }
        }
      }

      .__search__status__button {
        justify-content: flex-end;
        @include smaller-than-md {
          flex-direction: column;
        }
        a.home {
          font-size: 20px;
          font-weight: 500;
          line-height: 0%;
          text-transform: capitalize;
          color: $color-black-100;
          @include smaller-than-md {
            font-size: 16px;
          }
        }

        a#laptop {
          @include smaller-than-md {
            display: none;
          }
        }

        a#mobile {
          display: none;
          @include smaller-than-md {
            display: flex;
            margin: 30px 0px 20px;
          }
        }

        a#contact {
          width: 184px;
          margin-left: 32px;
          background-color: $color-blue-50;
          border: 0.5px solid $color-blue-30;
          -webkit-box-shadow: 0 4px 8px rgba(145, 209, 241, 0.3);
          -moz-box-shadow: 0 4px 8px rgba(145, 209, 241, 0.3);
          box-shadow: 0 4px 8px rgba(145, 209, 241, 0.3);
          border-radius: 5px;
          @include font-size('h5');
          @include font-weight('medium');
          @include line-height('h5');
          @include smaller-than-md {
            width: auto;
            @include font-size('body1');
            @include font-weight('medium');
            @include line-height('body1');
            margin: 0px;
          }
        }
      }

      .stepper-label {
        span {
          color: $color-black-100;
          @include font-size('body1');
          @include font-weight('bold');
          @include line-height('body1');
          @include smaller-than-md {
            @include font-weight('medium');
          }
        }
      }

      .stepper-content {
        p {
          text-align: center;
          @media screen and (max-width: 768px) {
            text-align: left;
          }
          color: $color-black-50;
          @include font-size('body1');
          @include font-weight('regular');
          @include line-height('body1');
          @include smaller-than-md {
            @include font-size('body2');
            @include line-height('body2');
          }
        }
      }

      .stepper-steps {
        .MuiStepLabel-iconContainer {
          .MuiStepIcon-root.MuiStepIcon-active {
            color: $color-blue-50;
          }
        }

        .MuiStepIcon-root {
          width: 26px;
          color: $color-black-50;
        }

        .MuiStepContent-root {
          border: none;
          padding: 0px;
          @include smaller-than-md {
            min-height: 1rem;
            border-left: 1px solid $color-black-50;
            padding-left: 24px;

            &.active {
              border-left: 1px solid $color-blue-50;
            }
          }
        }

        .MuiStepContent-last {
          border: none !important;
        }

        .MuiSvgIcon-root {
          height: initial;
          @include smaller-than-md {
            height: 1rem;   // fix display issue on mobile device
          }
        }
      }

      .MuiStepper-root {
        width: 808px;
        padding: 66px 0px;

        @include smaller-than-lg {
          width: max-content;
        }

        @include smaller-than-md {
          padding: 32px 0px;
        }
      }
    }

    .__search__status__error__img {
      margin-top: 81px;
      margin-bottom: 24px;

      @include smaller-than-md {
        margin-top: 32px;
        margin-bottom: 16px;
      }
    }

    .__search__status__error__title {
      margin-bottom: 16px;
      @include font-size('h3');
      @include font-weight('bold');
      @include line-height('h3');
      @include smaller-than-md {
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
      }
    }

    .__search__status__error__desc {
      @include font-size('h5');
      @include font-weight('regular');
      @include line-height('h5');

      @include smaller-than-md {
        @include font-size('body2');
        @include line-height('body2');
      }
    }
  }

  .invalid {
    width: 808px;
    height: 438px;
    text-align: center;

    @include smaller-than-lg {
      width: 600px;
    }

    @include smaller-than-md {
      width: 335px;
      height: 275px;
      margin: auto;
      margin-top: 144px;
      margin-bottom: 198px;
    }
  }
}

.search-snack-alert {
  .MuiPaper-elevation6 {
    -webkit-box-shadow: 0px 4px 8px rgba(24, 135, 114, 0.2);
    -moz-box-shadow: 0px 4px 8px rgba(24, 135, 114, 0.2);
    box-shadow: 0px 4px 8px rgba(24, 135, 114, 0.2);
    border-radius: 5px;
  }

  .MuiAlert-filledSuccess {
    margin-top: 70px;
    background-color: $color-green-50;
  }
  .MuiAlert-icon {
    margin: auto auto;
  }
  .MuiAlert-message {
    margin: 0px 0px 0px 16px;
    @include font-size('body1');
    @include font-weight('medium');
    @include line-height('body1');
    @include smaller-than-md {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}
