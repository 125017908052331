@import '../helpers/mixins';
@import '../helpers/variable';

footer {
  .footer {
    padding-top: 72px;
    padding-bottom: 92px;
    width: 100%;
    display: flex;
    position: absolute;
    background-color: $color-black-100;
    a:hover,
    a:focus {
      text-decoration: none;
    }

    p {
      color: $color-white-100;
      @include font-size('body1');
      @include font-weight('regular');
      @include line-height('body1');
      @include smaller-than-lg {
        @include font-size('body2');
        @include font-weight('regular');
        @include line-height('body2');
      }
    }

    li {
      margin-bottom: 24px;
    }

    li.last-link {
      margin-bottom: 0px;
    }

    .footer-copyright-text {
      margin-top: 32px;
    }

    .footer-policies {
      margin-top: 0px;
      @include smaller-than-lg() {
        margin-top: 56px;
      }

      .footer-policies-link {
        &:hover {
          color: $color-blue-50;
        }
        color: $color-white-100;
        @include font-size('body1');
        @include font-weight('regular');
        @include line-height('body1');
        @include smaller-than-lg {
          @include font-size('body2');
          @include font-weight('regular');
          @include line-height('body2');
        }
      }
    }

    .footer-contact-info {
      margin-top: 0px;
      @include smaller-than-lg() {
        margin-top: 56px;
      }

      .footer-contact-option {
        display: inline-block;

        img {
          vertical-align: bottom;
          margin-right: 17px;
        }
      }
      .footer-contact-detail {
        margin-left: 34px;
        p {
          @include font-size('body1');
          @include font-weight('regular');
          @include line-height('body1');
          @include smaller-than-lg {
            @include font-size('body2');
            @include font-weight('regular');
            @include line-height('body2');
          }
        }
      }
    }

    .footer-subtitle {
      margin-bottom: 32px;
      @include font-size('body1');
      @include font-weight('bold');
      @include line-height('body1');
      @include smaller-than-lg {
        @include font-size('body2');
        @include font-weight('bold');
        @include line-height('body2');
      }
    }
  }
}
