@import "./helpers/variableOverrideBS";
@import "~bootstrap/scss/bootstrap";
@import "./components/index";
@import "./pages/home";
@import "./pages/driver";
@import "./pages/refund";
@import "./pages/policy";
@import "./helpers/base";
@import "./pages/quickquote";
@import "./pages/search";

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*:focus {
  outline: none; }

html {
  min-width: 320px; }

body {
  position: relative;
  font-family: 'Roboto', 'PingFang SC', sans-serif; }
