@import '../helpers/variable';
@import '../helpers/mixins';

.__policy {
  user-select: none;
  background-color: $color-black-20;

  .row {
    max-width: 868px;
    margin: auto auto;
  }

  ul {
    padding-left: 20px;
    li {
      list-style: disc;
      margin-bottom: 20px;
      ul {
        margin-top: 12px;
        li {
          list-style: '- ';
        }
      }
    }
  }

  .__policy__title {
    margin-top: 96px;
    @include smaller-than-lg {
      margin-top: 32px;
    }
    text {
      margin-bottom: 0px;
      color: $color-blue-50;
      @include font-size('h3');
      @include font-weight('bold');
      @include line-height('h3');

      @include smaller-than-md {
        @include font-size('h4');
        @include font-weight('bold');
        @include line-height('h4');
      }
    }
  }

  .__policy__billboard {
    margin: 24px auto 0px;
    padding: 24px 32px;
    color: $color-black-100;
    background-color: $color-white-100;
    border: 1px solid $color-black-10;
    border-radius: 5px;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
    -moz-box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
    box-shadow: 0px 4px 16px rgba(7, 34, 74, 0.12);
    .__policy__billboard__title {
      margin: 0px 0px 24px;
      font-weight: 700;
      font-size: 24px;
      line-height: 28.13px;
      color: $color-black-100;
      @include smaller-than-md {
        font-weight: 700;
        font-size: 20px;
        line-height: 23.44px;
      }
    }

    .__policy__billboard__contents {
      color: $color-black-100;
      @include font-size('body1');
      @include font-weight('regular');
      @include line-height('body1');

      @include smaller-than-md {
        @include font-size('body2');
        @include font-weight('regular');
        @include line-height('body2');
      }
    }
  }

  .__policy__billboard {
    &:last-of-type {
      margin-bottom: 96px;

      @include smaller-than-lg {
        margin-bottom: 56px;
      }
    }
  }
}
