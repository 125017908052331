/** es2move GuideStyle */

/** Color */
// Primary Colors
$color-blue-80: #053a89;
$color-blue-70: #0058db;
$color-blue-50: #3397f7;
$color-blue-30: #69b4ff;
$color-blue-10: #bbddff;

// Secondary Colors
$color-yellow-100: #ca8219;
$color-yellow-90: #d69100;
$color-yellow-80: #e8a20e;
$color-yellow-70: #eeae27;
$color-yellow-50: #ffbb2a;
$color-yellow-30: #fbc85d;

$color-black-100: #07224a;
$color-black-80: #0e2a54;
$color-black-70: #505050;
$color-black-50: #818c9e;
$color-black-30: #a2aec3;
$color-black-20: #f9fbfd;
$color-black-10: #f8f9fb;
// $color-black-disable-shadow: #c6c6c6;

$color-pink-100: #e8988c;
$color-pink-70: #fec1b5;
$color-pink-50: #fddcd2;

$color-green-70: #14874c;
$color-green-50: #22ae66;

$color-white-100: #ffffff;

$color-orange-50: #f99444;

$color-red-50: #f62d2d;
$color-red-low: #ffeaea;

// default colors
$colors: (
  primary: $color-blue-50,
  blue: $color-blue-50,
  secondary: $color-yellow-50,
  yellow: $color-yellow-50,
  black: $color-black-100,
  red: $color-red-50,
  green: $color-green-50,
  orange: $color-orange-50,
  white: $color-white-100,
);

$btn: (
  prmary: (
    color: map-get($colors, white),
    background: map-get($colors, primary),
  ),
  secondary: (
    color: map-get($colors, white),
    background: map-get($colors, orange),
  ),
  gray: (
    color: map-get($colors, white),
    background: map-get($colors, gray),
  ),
);

/** Typography */
// TODO: fix size for md and lg
$font-size-h1: (
  xl: 48px,
  lg: 48px,
  md: 48px,
);
$font-size-h2: (
  xl: 40px,
  lg: 40px,
  md: 40px,
);
$font-size-h3: (
  xl: 32px,
  lg: 32px,
  md: 32px,
);
$font-size-h4: (
  xl: 24px,
  lg: 24px,
  md: 24px,
);
$font-size-h5: (
  xl: 20px,
  lg: 20px,
  md: 20px,
);
$font-size-body1: (
  xl: 16px,
  lg: 16px,
  md: 16px,
);
$font-size-body2: (
  xl: 14px,
  lg: 14px,
  md: 14px,
);
$font-size-caption: (
  xl: 12px,
  lg: 12px,
  md: 12px,
);
$font-size-overline: (
  xl: 10px,
  lg: 10px,
  md: 10px,
);

/** Media query */
$desktop-media: 1440px;
$desktop-small-media: 1024px;
$mobile-media: 768px;
$mobile-h-media: 480px;

$width-xl: 1440px;
$width-lg: 1024px;
$width-md: 768px;
$width-sm: 480px;

@mixin more-than-lg() {
  @media all and (min-width: $width-lg - 1) {
    @content;
  }
}

@mixin smaller-than-xl() {
  @media all and (max-width: $width-xl - 1) {
    @content;
  }
}

@mixin smaller-than-lg() {
  @media all and (max-width: $width-lg - 1) {
    @content;
  }
}

@mixin smaller-than-md() {
  @media all and (max-width: $width-md - 1) {
    @content;
  }
}

@mixin smaller-than-sm() {
  @media all and (max-width: $width-sm - 1) {
    @content;
  }
}

//desktop
@mixin desktop-width() {
  @media all and (min-width: $desktop-media) {
    @content;
  }
}
//desktop small
@mixin desktop-small-width() {
  @media all and (max-width: $desktop-small-media - 1) {
    @content;
  }
}
//pad
@mixin pad-width() {
  @media all and (min-width: $mobile-media) and (max-width: $desktop-media - 1) {
    @content;
  }
}
//mobile
@mixin mobile-width() {
  @media all and (max-width: $mobile-media - 1) {
    @content;
  }
}
//mobile-horizontal
@mixin mobile-horizontal-width() {
  @media all and (max-width: $mobile-h-media - 1) {
    @content;
  }
}

/** Font size */
$getFontSize: (
  desktop: (
    'h1': 48px,
    'h2': 40px,
    'h3': 32px,
    'h4': 24px,
    'h5': 20px,
    'body1': 16px,
    'body2': 14px,
    'caption': 12px,
    'overline': 10px,
  ),
  pad: (
    'h1': 48px,
    'h2': 40px,
    'h3': 32px,
    'h4': 24px,
    'h5': 20px,
    'body1': 16px,
    'body2': 14px,
    'caption': 12px,
    'overline': 10px,
  ),
  mobile: (
    'h1': 48px,
    'h2': 40px,
    'h3': 32px,
    'h4': 24px,
    'h5': 20px,
    'body1': 16px,
    'body2': 14px,
    'caption': 12px,
    'overline': 10px,
  ),
);

@mixin font-size($size) {
  @include desktop-width {
    $desktop: map-get($getFontSize, desktop);
    font-size: map-get($desktop, $size);
  }
  @include pad-width {
    $pad: map-get($getFontSize, pad);
    font-size: map-get($pad, $size);
  }
  @include mobile-width {
    $mobile: map-get($getFontSize, mobile);
    font-size: map-get($mobile, $size);
  }
};

$getFontWeight: (
  'bold': 700,
  'medium': 500,
  'regular': 400,
);

@mixin font-weight($size) {
  font-weight: map-get($getFontWeight, $size);
};

$getLineHeight: (
  'h1': 56px,
  'h2': 48px,
  'h3': 40px,
  'h4': 32px,
  'h5': 28px,
  'body1': 22px,
  'body2': 20px,
  'caption': 14px,
  'overline': 12px,
);

@mixin line-height($size) {
  line-height: map-get($getLineHeight, $size);
};
