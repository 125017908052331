@import '../helpers/variable';
@import '../helpers/mixins';
.__page__main {
  position: relative;
  background-color: $color-blue-50;
  color: $color-white-100;

  .adjusted-container {
    @include smaller-than-lg {
      max-width: 600px;
      margin: auto;
      padding: 0px;
    }
  }

  .__page__main__bg {
    width: 100%;

    &.laptop {
      display: inherit;
      padding-top: 85px;
      @include smaller-than-xl {
        padding-top: 200px;
      }
      @include smaller-than-lg {
        display: none;
      }
    }

    &.mobile {
      display: none;
      @include smaller-than-lg {
        padding-top: 32px;
        display: flex;
      }
      @include smaller-than-md {
        padding-top: 16px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      vertical-align: bottom;
    }
  }

  .__page__main__title {
    text-align: left;
    padding: 0px;
    padding-top: 72px;
    z-index: 1;
    @include smaller-than-lg {
      padding-top: 32px;
    }

    text {
      @include font-size('h1');
      @include font-weight('bold');
      @include line-height('h1');

      @include smaller-than-xl {
        @include font-size('h2');
        @include font-weight('bold');
        @include line-height('h2');
      }
    }

    br#title-break-one {
      display: none;
      @include smaller-than-xl {
        display: inline;
      }
      @include smaller-than-lg {
        display: none;
      }
    }

    br#title-break-two {
      @include smaller-than-lg {
        display: none;
      }
    }
  }
  #deliveryType {
    .MuiSelect-icon {
      color: #07224a;
    }
  }
  .__page__main__body {
    padding: 0px;
    width: 600px;
    margin: auto;
    position: absolute;
    top: 44px;
    right: 108px;
    z-index: 1;
    @include smaller-than-lg {
      position: relative;
      top: initial;
      right: initial;
      margin-top: 64px;
    }
    @include smaller-than-md {
      margin-top: 0px;
      padding-top: 32px;
    }
    .__page__main__form {
      min-width: 280px;
      border-radius: 5px;
      background-color: $color-white-100;
      padding: 24px 32px 32px 32px;

      .__page__main__form-control {
        margin-bottom: 24px;

        .form-category {
          img {
            padding-right: 8px;
          }

          .MuiSelect-root {
            height: auto;
          }

          .MuiInputBase-root {
            width: inherit;
          }
        }

        .MuiInputBase-input {
          padding: 0px;
          @include font-size('h5');
          @include font-weight('medium');
          @include line-height('h5');
          @include smaller-than-sm {
            @include font-size('body1');
            @include font-weight('medium');
            @include line-height('body1');
          }
        }

        .MuiInputBase-inputAdornedEnd {
          width: fit-content;
          font-size: 100px;
          text-align: end;
        }

        .MuiSvgIcon-root {
          top: auto;
        }

        .__body__prices__type__btn {
          display: inline;
          background-color: $color-black-10;
          color: $color-blue-50;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          @include smaller-than-lg {
            font-size: 14px;
          }
        }

        .left {
          width: 50%;
          border: 1.5px solid #3397f7;
          border-radius: 5px 0px 0px 5px;
          &.active {
            background-color: $color-blue-50;
            color: $color-white-100;
          }
        }

        .right {
          width: 50%;
          border: 1.5px solid #3397f7;
          border-left: none;
          border-radius: 0px 5px 5px 0px;
          &.active {
            background-color: $color-yellow-50;
            color: $color-white-100;
          }
        }

        .btn {
          cursor: pointer;
        }

        .express {
          color: $color-yellow-50;
          border: 1.5px solid #ffbb2a;
        }

        .MuiSelect-icon {
          color: #07224a;
        }
      }
      .__page__main__form-title {
        margin-top: 24px;
        color: $color-blue-50;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.13px;

        &.second {
          margin-top: 14px;
          @include smaller-than-md {
            margin-top: 12px;
          }
        }

        @include smaller-than-md {
          font-size: 20px;
          line-height: 23.44px;
        }

        &.active {
          color: $color-yellow-50;
        }
        .result-page {
          margin-bottom: 8px;
        }
      }
      .__page__main__form-body {
        min-height: 78px;
        padding-top: 15px;

        @include smaller-than-md {
          padding-top: 0px;
        }

        &.first {
          @include smaller-than-md {
            padding-top: 15px;
          }
        }

        &.second {
          padding-top: 5px;
          @include smaller-than-md {
            padding-top: 3px;
          }
        }

        .p-r-mod {
          padding-right: 12px;
          @include smaller-than-md {
            padding-right: 15px;
          }
        }

        .p-l-mod {
          padding-left: 12px;
          @include smaller-than-md {
            padding-left: 15px;
          }
        }
        // padding-bottom: 24px;

        // subtitle
        .MuiFormLabel-root {
          color: $color-black-50;
          @include font-size('body1');
          @include font-weight('regular');
          @include line-height('body1');
          @include smaller-than-md {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
        }

        // select contents
        .MuiSelect-root {
          padding: 16px 0px 9px;
          @include font-size('body1');
          @include font-weight('regular');
          @include line-height('body1');
          @include smaller-than-md {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
        }

        // postcode
        .MuiAutocomplete-inputRoot {
          padding: 14px 0px 0px;
          @include font-size('body1');
          @include font-weight('regular');
          @include line-height('body1');
          @include smaller-than-md {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
        }

        // shipment details
        .MuiInputBase-formControl {
          padding: 16px 0px 9px;
          @include font-size('body1');
          @include font-weight('regular');
          @include line-height('body1');
          @include smaller-than-md {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
        }

        // change underline color for this page
        .MuiInput-underline:before {
          border-bottom-color: $color-black-50;
        }

        .MuiSelect-icon {
          color: $color-black-50;
        }

        .MuiInputBase-inputAdornedEnd {
          width: 75%;
        }

        .MuiInputBase-input::placeholder {
          color: $color-black-50;
          opacity: 1;
        }

        span.form-subtitle {
          color: $color-black-50;
        }
      }

      .__page__main__form-submit {
        padding-top: 14px;

        @include smaller-than-md {
          padding-top: 12px;
        }

        .MuiButton-root {
          padding: 0px;
        }

        .clear-btn-div {
          padding-right: 8px;
          @include smaller-than-md {
            padding: 20px 15px 0px;
          }
        }

        .submit-btn-div {
          padding-left: 8px;
          @include smaller-than-md {
            padding: 0px 15px;
          }
        }

        .submit__button {
          background-color: $color-blue-50;
          padding: 10px 0px;
          -webkit-box-shadow: 0px 4px 16px rgba(145, 209, 241, 0.3);
          -moz-box-shadow: 0px 4px 16px rgba(145, 209, 241, 0.3);
          box-shadow: 0px 4px 16px rgba(145, 209, 241, 0.3);
          span {
            color: $color-white-100;
            @include font-size('h5');
            @include font-weight('medium');
            @include line-height('h5');
          }
          &.active {
            background-color: $color-yellow-50;
          }
        }
        .clear__button {
          padding: 10px 0px;
          span {
            color: $color-black-100;
            @include font-size('h5');
            @include font-weight('medium');
            @include line-height('h5');
          }
        }
      }

      .__page__main__form-price {
        margin-top: 4px;
        margin-bottom: 28px;
        #quote-title {
          margin: auto;
          margin-left: 32px;
          color: $color-black-100;
          @include font-size('h4');
          @include font-weight('medium');
          @include line-height('h4');
        }
        #price {
          color: $color-blue-50;
          @include font-size('h2');
          @include font-weight('bold');
          @include line-height('h1');
          &.active {
            color: $color-yellow-50;
          }
        }
      }

      .__page__main__form-detail {
        padding-top: 16px;
        padding-bottom: 8px;
        text#label {
          color: $color-black-100;
          font-size: 16px;
          @include font-weight('medium');
          line-height: 24px;
        }
        text#detail {
          color: $color-black-100;
          font-size: 16px;
          @include font-weight('regular');
          line-height: 24px;
        }
      }
      .last {
        padding-bottom: 32px;
      }

      #divider {
        background-color: #818c9e;
        margin: 0px -32px;
      }
      .margin-md {
        @include smaller-than-md {
          padding-top: 39px;
        }

        &.ship-detail {
          @include smaller-than-md {
            padding-top: 3px !important;
          }
        }

        &.first {
          @include smaller-than-md {
            padding-top: 15px !important;
          }
        }

        .input-field {
          min-height: 78px;
          .MuiFormHelperText-root {
            @include font-size('body1');
            @include font-weight('regular');
            @include line-height('body1');
          }
        }

        .postcode-invalid-helpertext {
          height: 39px;
          padding-top: 7px;
          color: $color-red-50;
          @include font-size('body1');
          @include font-weight('regular');
          @include line-height('body1');
        }

        .invalid-label {
          color: $color-red-50;
        }

        // shipments details section
        .MuiInputBase-input {
          padding: 0px;
        }

        // postcode section
        .MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child {
          padding: 0px;
        }
      }

      .margin-btn {
        @include smaller-than-md {
          padding-top: 21px;
        }
      }
      .order {
        @include smaller-than-md {
          order: -1;
        }
      }
    }
  }
}
